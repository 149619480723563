<template>
  <div class="NotificationsWebhook" v-if="user.subscription_plan.apps_alerts">
    <div class="top">
      <div class="title">Discord Notification</div>
    </div>
    <div class="card">
      <div class="card-body">
        <discord-notification :monitor="monitor"
                              :general="general"
                              @update="$emit('update')" />
      </div>
    </div>
  </div>
  <div v-else>
    <div class="upgrade">
      <UpgradeBadge text="Discord is available only for paid customers."/>
    </div>
  </div>
</template>

<script>
import DiscordNotification from '@/components/Monitor/Notifications/DiscordWebhook/Discord.vue'
import UpgradeBadge from '@/components/Common/UpgradeBadge.vue'

export default {
  components: {
    UpgradeBadge,
    DiscordNotification
  },

  metaInfo: {
    title: 'Discord Notifications'
  },

  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  computed: {
    ...mapState('authentication', ['user'])
  }
}
</script>

<style lang="scss" scoped>
.NotificationsWebhook {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.5;
      color: #444;
    }
  }
}
.upgrade {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  @media (max-width: 767px) {
    margin: 24px 0;
    min-height: unset !important;
  }
}
</style>
